import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from 'components/SEO'

import styles from './CountryBlock.module.scss'

const CountryBlockPage = ({ data }) => {
  const { earthMap, banner } = data

  return (
    <>
    <SEO
      title={`Content not available in your country | SloppyDeep`}
      description={`Sorry! The model has not made this content available in your country`}
      keywords={``}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/country-block/`}
      robots='noindex, nofollow'
    />
    <div className={styles.countryBlockScreen}>
      <Img fluid={earthMap.childImageSharp.fluid} className={styles.backgroundImg} />
      <div className={styles.content}>
        <h1 className={styles.title}>oops!</h1>
        <p className={styles.details}>
          The model has not made this content<br/>
          available in your country
        </p>
      </div>
    </div>
    </>
  )
}

export const query = graphql`
  query CountryBlockPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    earthMap: file(
      relativeDirectory: {regex: "/"},
      name: {eq: "earth-map"}
    ) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 70) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

  }
`

export default CountryBlockPage
